import React, { useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import AllShopsPagePlaceholder from '../AllShopsPagePlaceholder/AllShopsPagePlaceholder';
import Footer from '../Footer/Footer';
import NewsletterSection from '../NewsletterSection/NewsletterSection';
import RecentlyViewedProducts from '../RecentlyViewedProducts/RecentlyViewedProducts';
import StickyHeader from '../StickyHeader/StickyHeader';

import styles from './AllCategoriesPage.module.scss';
import { Link } from 'react-router-dom';
import { useCategoryLists } from '../../utils/useCategoryLists';

const AllCategoriesPage = ({ config, intl }) => {
  const [selectedLetter, setSelectedLetter] = useState(false);
  const { categoryTree } = useCategoryLists({ categoryTree: { onMount: true } });

  let categoriesFlat = [];
  let categoriesMap = [];
  if (categoryTree) {
    Object.keys(categoryTree).map((category) => {
      const main = categoryTree[category];
      categoriesFlat.push({ slug: main.slug, name: main.name, key: main.key, path: `${main.slug}/` });

      Object.keys(main.subCategories).map((subCategory) => {
        const sub = main.subCategories[subCategory];
        categoriesFlat.push({ slug: sub.slug, name: sub.key, key: sub.key, path: `${main.slug}/${sub.slug}/` });

        Object.keys(sub.subSubCategories).map((subSubCategory) => {
          const subSub = sub.subSubCategories[subSubCategory];
          categoriesFlat.push({
            slug: subSub.slug,
            name: subSub.key,
            key: subSub.key,
            path: `${main.slug}/${sub.slug}/${subSub.slug}/`,
          });

          return subSub;
        });

        return sub;
      });

      return main;
    });

    categoriesMap = {
      '0-9': categoriesFlat.filter((category) => !isNaN(parseInt(category.name[0], 10))),
    };

    for (let charCode = 65; charCode < 91; charCode++) {
      const letter = String.fromCharCode(charCode);
      categoriesMap[letter] = categoriesFlat
        .filter((category) => category.name[0].toUpperCase() === letter)
        .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
    }
  }

  const scrollToShop = (letter) => {
    setSelectedLetter(letter);
    const elm = document.getElementById(letter);
    window.scrollTo({ behavior: 'smooth', left: 0, top: elm.offsetTop - 75 });
  };

  const allCategoriesTitle = `${intl.messages['categories.headerAll']} - ${config?.websiteName}`;

  return (
    <>
      <Helmet>
        <title>{allCategoriesTitle}</title>
        <meta
          property="og:url"
          content={`${config.baseUrl}/${intl.messages['footer.allCategories'].toLowerCase().replace(' ', '-')}/`}
        />
        <link
          rel="canonical"
          href={`${config.baseUrl}/${intl.messages['footer.allCategories'].toLowerCase().replace(' ', '-')}/`}
        />
      </Helmet>
      <StickyHeader />
      {!categoryTree && <AllShopsPagePlaceholder />}
      {!!categoryTree && (
        <div className={classNames(styles.allShops, 'container')} data-testid="allShopsPage" id="readyForCrawler">
          <div className="row">
            <section className="col-12-xs">
              <h1 className={styles.title}>
                <FormattedMessage id="common.brands.all" defaultMessage="All" />{' '}
                <span>
                  <FormattedMessage id="footer.categories" defaultMessage="categorieen" />
                </span>
              </h1>
              <nav className={styles.navigation}>
                {Object.keys(categoriesMap).map((letter, index) => {
                  return (
                    <span
                      className={classNames(styles.letter, {
                        [styles.disabled]: !categoriesMap[letter].length,
                        [styles.selected]: letter === selectedLetter,
                      })}
                      data-testid={`allShopsScroll${letter.toLowerCase()}`}
                      key={letter + index}
                      onClick={() => scrollToShop(letter.toUpperCase())}
                    >
                      {letter}
                    </span>
                  );
                })}
              </nav>
              {Object.keys(categoriesMap)
                .filter((letter) => categoriesMap[letter].length)
                .map((letter, index) => (
                  <section className={styles.section} id={letter} key={letter + index}>
                    <h2 className={styles.sectionTitle}>
                      <b>{letter.toUpperCase()}</b>
                    </h2>
                    <div className={classNames(styles.shopList, 'row')}>
                      <div className={styles.wrapWebsite}>
                        {categoriesMap[letter].map((category, index) => (
                          <div className={classNames(styles.website)} key={category.name + index}>
                            <Link className={styles.shopName} rel="noopener noreferrer" to={`/${category.path}`}>
                              {category.name}
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </section>
                ))}
            </section>
          </div>
        </div>
      )}
      <RecentlyViewedProducts />
      <NewsletterSection />
      <Footer />
    </>
  );
};

AllCategoriesPage.displayName = 'AllCategoriesPage';

const mapStateToProps = ({ config, intl, profile }) => ({
  config,
  intl,
  profile,
});

export default connect(mapStateToProps)(AllCategoriesPage);
