import React from 'react';
import classnames from 'classnames';

import styles from './AllShopsPagePlaceholder.module.scss';

const AllShopsPagePlaceholder = () => {
  return (
    <div data-test-id="AllShopsPagePlaceholder" className={classnames(styles.allShops, 'container')}>
      <div className="row">
        <section className="col-12-xs">
          <p className={styles.title}></p>
          <nav className={styles.navigation}>
            <div className={styles.letter}></div>
            <div className={styles.letter}></div>
            <div className={styles.letter}></div>
            <div className={styles.letter}></div>
            <div className={styles.letter}></div>
            <div className={styles.letter}></div>
            <div className={styles.letter}></div>
            <div className={styles.letter}></div>
            <div className={styles.letter}></div>
            <div className={styles.letter}></div>
            <div className={styles.letter}></div>
            <div className={styles.letter}></div>
            <div className={styles.letter}></div>
            <div className={styles.letter}></div>
            <div className={styles.letter}></div>
            <div className={styles.letter}></div>
          </nav>
          <p className={styles.line}></p>

          <section className={styles.section}>
            <p className={styles.sectionTitle}></p>
            <p className={styles.line}></p>
            <div className={classnames(styles.shopList, 'row')}>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
            </div>
          </section>

          <section className={styles.section}>
            <p className={styles.sectionTitle}></p>
            <p className={styles.line}></p>
            <div className={classnames(styles.shopList, 'row')}>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
            </div>
          </section>

          <section className={styles.section}>
            <p className={styles.sectionTitle}></p>
            <p className={styles.line}></p>
            <div className={classnames(styles.shopList, 'row')}>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
            </div>
          </section>

          <section className={styles.section}>
            <p className={styles.sectionTitle}></p>
            <p className={styles.line}></p>
            <div className={classnames(styles.shopList, 'row')}>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
              <div className={classnames(styles.website, 'col-12-xs col-6-m col-4-l col-2-xl')}>
                <p className={styles.shopName}></p>
              </div>
            </div>
          </section>
        </section>
      </div>
    </div>
  );
};

AllShopsPagePlaceholder.displayName = 'AllShopsPagePlaceholder';
export default AllShopsPagePlaceholder;
